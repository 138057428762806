import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, Typography, Grid, Alert } from '@mui/material';
import { checkGithubConnectivity, updateGitHubSettings, configureGitHubRepo, fetchAwsSettings } from './api';

const GitHubSettings = ({ settings, setSettings, tokenLength, setTokenLength }) => {
  const [message, setMessage] = useState(null);
  const [messageType, setMessageType] = useState('success');
  const [roleArn, setRoleArn] = useState('');
  const [awsRegion, setAwsRegion] = useState('');

  const handleChange = (e) => {
    setSettings({ ...settings, [e.target.name]: e.target.value });
    if (e.target.name === 'githubToken') {
      setTokenLength(e.target.value.length);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await updateGitHubSettings(settings.githubToken, settings.githubRepo, settings.githubOwner, settings.githubBranch);
    if (result.success) {
      setTokenLength(settings.githubToken.length);
      setMessage('Settings updated successfully');
      setMessageType('success');
    } else {
      setMessage('Failed to update settings');
      setMessageType('error');
    }
  };

  const handleCheckConnectivity = async () => {
    const result = await checkGithubConnectivity(settings.githubToken, settings.githubRepo, settings.githubOwner, settings.githubBranch);
    if (result.success) {
      setMessage('GitHub connectivity check successful');
      setMessageType('success');
    } else {
      setMessage(`GitHub connectivity check failed: ${result.error}`);
      setMessageType('error');
    }
  };

  const handleConfigureRepo = async () => {
    const owner = settings.githubOwner;
    const repo = settings.githubRepo;

    if (!roleArn || !awsRegion) {
      setMessage('AWS settings are incomplete. Please check the configuration.');
      setMessageType('error');
      return;
    }

    try {
      const result = await configureGitHubRepo(owner, repo, roleArn, awsRegion);
      if (result.error) {
        setMessage(`Error configuring GitHub repository: ${result.error}`);
        setMessageType('error');
      } else {
        setMessage('GitHub repository configured successfully');
        setMessageType('success');
      }
    } catch (error) {
      setMessage('Failed to communicate with the backend. Check console for details.');
      setMessageType('error');
    }
  };

  useEffect(() => {
    const fetchAwsSettingsData = async () => {
      try {
        const awsSettings = await fetchAwsSettings();
        setRoleArn(awsSettings.role_arn);
        setAwsRegion(awsSettings.region);
      } catch (error) {
        console.error('Error fetching AWS settings:', error);
      }
    };
    fetchAwsSettingsData();
  }, []);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {message && (
        <Alert severity={messageType} sx={{ mt: 2, width: '100%' }}>
          {message}
        </Alert>
      )}
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              type="password"
              label="GitHub Token"
              name="githubToken"
              value={settings.githubToken ? '*'.repeat(tokenLength) : ''}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="GitHub Repository"
              name="githubRepo"
              value={settings.githubRepo || ''}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="GitHub Owner"
              name="githubOwner"
              value={settings.githubOwner || ''}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="GitHub Branch"
              name="githubBranch"
              value={settings.githubBranch || ''}
              onChange={handleChange}
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            mt: 2,
            textTransform: 'none',
            borderRadius: '24px',
            backgroundColor: '#ffffff',
            borderColor: '#e0e0e0',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: '#f1f3f5',
              borderColor: '#c0c0c0',
            },
          }}
        >
          Update Settings
        </Button>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="secondary"
        onClick={handleCheckConnectivity}
        sx={{
          mt: 2,
          textTransform: 'none',
          borderRadius: '24px',
          backgroundColor: '#ffffff',
          borderColor: '#e0e0e0',
          color: '#1976d2',
          '&:hover': {
            backgroundColor: '#f1f3f5',
            borderColor: '#c0c0c0',
          },
        }}
      >
        Check GitHub Connectivity
      </Button>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={handleConfigureRepo}
        sx={{
          mt: 2,
          mb: 2,
          textTransform: 'none',
          borderRadius: '24px',
          backgroundColor: '#ffffff',
          borderColor: '#e0e0e0',
          color: '#1976d2',
          '&:hover': {
            backgroundColor: '#f1f3f5',
            borderColor: '#c0c0c0',
          },
        }}
      >
        Configure GitHub Repository
      </Button>
    </Box>
  );
};

export default GitHubSettings;
