import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Container, Paper, Link } from '@mui/material';
import config from './config';
import GoogleLogo from './assets/google.svg';
import Logo from './assets/logo.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async () => {
    try {
      const response = await fetch(`${config.apiUrl}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include'
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(errorText);
      }

      window.location.href = '/dashboard'; // Redirect to dashboard after successful login
    } catch (error) {
      console.error('Error during login:', error.message);
      alert('Invalid credentials');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${config.apiUrl}/auth/google`;
  };

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={6} sx={{ padding: 3, marginTop: 8, backgroundColor: 'rgba(255, 255, 255, 0.9)', paddingTop: '80px', position: 'relative' }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', position: 'absolute', top: '20px', left: '50%', transform: 'translateX(-50%)' }}>
          <img src={Logo} alt="Exacto Logo" style={{ width: '100px', height: '100px' }} />
        </Box>
        <Typography component="h1" variant="h5" align="center" sx={{ fontWeight: 'bold', marginTop: '60px' }}>
          Log in
        </Typography>
        <Box component="form" onSubmit={(e) => { e.preventDefault(); handleLogin(); }} sx={{ mt: 2 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username or email address"
            name="username"
            autoComplete="username"
            autoFocus
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            sx={{ borderRadius: '50px' }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ borderRadius: '50px' }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: 'bold',
              fontSize: '14px',
              backgroundColor: '#0070f3',
              borderRadius: '50px',
              textTransform: 'none'
            }}
          >
            CONTINUE
          </Button>
          <Typography align="center" sx={{ mt: 2, mb: 1, color: 'rgba(0, 0, 0, 0.54)' }}>
            or
          </Typography>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              mt: 1,
              mb: 1,
              fontWeight: 'bold',
              fontSize: '14px',
              borderColor: '#ccc',
              color: 'black',
              borderRadius: '50px',
              textTransform: 'none'
            }}
            onClick={handleGoogleLogin}
          >
            <img src={GoogleLogo} alt="Google logo" style={{ marginRight: '8px', height: '20px' }} />
            Sign in with Google
          </Button>
          <Button
            fullWidth
            variant="outlined"
            sx={{
              mt: 1,
              mb: 2,
              fontWeight: 'bold',
              fontSize: '14px',
              borderColor: '#ccc',
              color: 'black',
              borderRadius: '50px',
              textTransform: 'none'
            }}
            onClick={() => alert('One-time login code feature not implemented yet')}
          >
            Get a one-time login code
          </Button>
          <Link href="/signup" variant="body2" sx={{ display: 'block', textAlign: 'center', color: '#0070f3', textDecoration: 'none' }}>
            Don't have an account? Sign up
          </Link>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;
