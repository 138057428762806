import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid } from '@mui/material';
import { updateSlackSettings, fetchSlackSettings } from './api';

const SlackSettings = () => {
  const [slackId, setSlackId] = useState('');
  const [slackChannel, setSlackChannel] = useState('');
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetchSlackSettings();
        if (response.slack_id) {
          setSlackId(response.slack_id);
        }
        if (response.slack_channel) {
          setSlackChannel(response.slack_channel);
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching Slack settings:', error);
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const handleSlackIdChange = (e) => {
    setSlackId(e.target.value);
  };

  const handleSlackChannelChange = (e) => {
    setSlackChannel(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await updateSlackSettings(slackId, slackChannel);
      if (result.success) {
        setMessage('Slack settings updated successfully');
      } else {
        setMessage('Failed to update Slack settings');
      }
    } catch (error) {
      console.error('Error updating Slack settings:', error);
      setMessage('Error updating Slack settings');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component="h1" variant="h5" align="center">
        Slack Settings
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Slack ID"
              name="slackId"
              value={slackId}
              onChange={handleSlackIdChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              fullWidth
              label="Slack Channel"
              name="slackChannel"
              value={slackChannel}
              onChange={handleSlackChannelChange}
              required
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          sx={{
            mt: 3,
            mb: 2,
            textTransform: 'none',
            borderRadius: '24px',
            backgroundColor: '#ffffff',
            borderColor: '#e0e0e0',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: '#f1f3f5',
              borderColor: '#c0c0c0',
            },
          }}
        >
          Update Slack Settings
        </Button>
      </Box>
      {message && (
        <Typography
          sx={{
            mt: 2,
            color: message.includes('success') ? 'green' : 'red',
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default SlackSettings;
