// src/components/PageLayout.js

import React from 'react';
import { Box, Container, Toolbar, Paper, Typography } from '@mui/material';
import Sidebar from './Sidebar';

const PageLayout = ({ children, title, maxWidth = 'md' }) => {
  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', backgroundColor: '#f8f9fa' }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Container component="main" maxWidth={maxWidth}>
          <Paper elevation={6} sx={{ p: 3, backgroundColor: 'white', borderRadius: '12px', boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            {title && (
              <Typography component="h1" variant="h6" align="left" sx={{ mb: 2, fontFamily: 'Inter, Arial, sans-serif' }}>
                {title}
              </Typography>
            )}
            {children}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
};

export default PageLayout;
