import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { SidebarProvider } from './context/SidebarContext';
import { AuthProvider } from './context/AuthContext';
import Sidebar from './components/Sidebar';
import Login from './Login';
import Dashboard from './Dashboard';
import Account from './Account';
import CreatePermissionChange from './CreatePermissionChange';
import ShowPermissionChangeHistory from './ShowPermissionChangeHistory';
import Reports from './Reports';
import Signup from './Signup';
import Settings from './Settings';
import ProtectedRoute from './context/ProtectedRoute';
import Logout from './Logout'; // Ensure all paths are correct

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <SidebarProvider>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/logout" element={<Logout />} />
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <Sidebar />
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/create-permission-change" element={<CreatePermissionChange />} />
                    <Route path="/permission-change-history" element={<ShowPermissionChangeHistory />} />
                    <Route path="/reports" element={<Reports />} />
                    <Route path="/settings" element={<Settings />} />
                    <Route path="*" element={<Navigate to="/dashboard" />} /> {/* Redirect root to /dashboard */}
                  </Routes>
                </ProtectedRoute>
              }
            />
          </Routes>
        </SidebarProvider>
      </AuthProvider>
    </Router>
  );
};

export default App;
