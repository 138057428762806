import React, { useState, useEffect } from 'react';
import { TextField, Button, Avatar, Typography, Box, Grid, MenuItem, CircularProgress } from '@mui/material';
import config from './config';
import PageLayout from './components/PageLayout';

const Account = () => {
  const [user, setUser] = useState({});
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('');
  const [organization, setOrganization] = useState('');
  const [departments, setDepartments] = useState('');
  const [avatar, setAvatar] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/profile`, {
          credentials: 'include'
        });
        const data = await response.json();
        setUser(data);
        setEmail(data.email);
        setName(data.name);
        setRole(data.role);
        setOrganization(data.organization || '');
        setDepartments(data.departments || '');  // Handle as a comma-separated string
        setAvatar(data.avatar_url);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };
    fetchUser();
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('email', email);
    formData.append('name', name);
    formData.append('role', role);
    formData.append('organization', organization);
    formData.append('departments', departments);
    if (avatar instanceof File) formData.append('avatar', avatar);

    const response = await fetch(`${config.apiUrl}/profile/update-profile`, {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    if (response.ok) {
      const updatedUser = await response.json();
      setUser(updatedUser);
      setAvatar(updatedUser.avatar_url);
      const sidebarAvatar = document.getElementById('sidebar-avatar');
      if (sidebarAvatar) {
        sidebarAvatar.src = updatedUser.avatar_url;
      }
    } else {
      alert('Failed to update profile');
    }
  };

  const handleAvatarChange = (e) => {
    setAvatar(e.target.files[0]);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <PageLayout title="Account Settings">
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar src={avatar instanceof File ? URL.createObjectURL(avatar) : avatar} sx={{ width: 100, height: 100, mb: 2 }} />
        <Typography component="h1" variant="h5">
          Account Details
        </Typography>
        <Box component="form" onSubmit={handleUpdate} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                select
                label="Role"
                name="role"
                value={role}
                onChange={(e) => setRole(e.target.value)}
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="reviewer">Reviewer</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Organization"
                name="organization"
                value={organization}
                onChange={(e) => setOrganization(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Departments"
                name="departments"
                value={departments}
                onChange={(e) => setDepartments(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                component="label"
                sx={{
                  mt: 2,
                  textTransform: 'none',
                  borderRadius: '24px',
                  backgroundColor: '#ffffff',
                  borderColor: '#e0e0e0',
                  color: '#1976d2',
                  '&:hover': {
                    backgroundColor: '#f1f3f5',
                    borderColor: '#c0c0c0',
                  },
                }}
              >
                Upload Avatar
                <input type="file" hidden onChange={handleAvatarChange} />
              </Button>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            sx={{
              mt: 3,
              mb: 2,
              textTransform: 'none',
              borderRadius: '24px',
              backgroundColor: '#ffffff',
              borderColor: '#e0e0e0',
              color: '#1976d2',
              '&:hover': {
                backgroundColor: '#f1f3f5',
                borderColor: '#c0c0c0',
              },
            }}
          >
            Update Profile
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Account;
