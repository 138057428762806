import axios from 'axios';
import config from './config';

export const updateGitHubSettings = async (githubToken, githubRepo, githubOwner, githubBranch) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings`, {
      github_token: githubToken,
      github_repo: githubRepo,
      github_owner: githubOwner,
      github_branch: githubBranch,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating GitHub settings:', error);
    return { error: error.message };
  }
};

export const checkGithubConnectivity = async (githubToken, githubRepo, githubOwner, githubBranch) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/check-github-connectivity`, {
      github_token: githubToken,
      github_repo: githubRepo,
      github_owner: githubOwner,
      github_branch: githubBranch,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error checking GitHub connectivity:', error);
    return { error: error.message };
  }
};

export const fetchAwsSettings = async () => {
  try {
    console.log('Fetching AWS settings...');
    const response = await axios.get(`${config.apiUrl}/settings/aws`, {
      withCredentials: true,
    });
    console.log('AWS settings response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching AWS settings:', error);
    return { error: error.message };
  }
};

export const updateAWSSettings = async (awsAccountId, region, roleArn) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/aws`, {
      aws_account_id: awsAccountId,
      region,
      role_arn: roleArn,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating AWS settings:', error);
    return { error: error.message };
  }
};

export const downloadCloudFormationScript = async (awsAccountId, region, githubOwner, githubRepo) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/cloudformation-script`, {
      aws_account_id: awsAccountId,
      region,
      github_org: githubOwner,
      repository_name: githubRepo,
    }, {
      responseType: 'blob',
      withCredentials: true,
    });
    return { success: true, template: response.data };
  } catch (error) {
    console.error('Error generating CloudFormation script:', error);
    return { success: false, error: error.message };
  }
};

export const saveAwsAccount = async (awsAccountId, region) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/aws`, {
      aws_account_id: awsAccountId,
      region,
    }, {
      withCredentials: true,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error saving AWS account:', error);
    return { success: false, error: error.message };
  }
};

export const saveAwsRoleArn = async (awsAccountId, roleArn) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/save-aws-role-arn`, {
      aws_account_id: awsAccountId,
      role_arn: roleArn,
    }, {
      withCredentials: true,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error saving AWS role ARN:', error);
    return { success: false, error: error.message };
  }
};

export const checkAwsAccess = async (awsAccountId) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/check-aws-access`, {
      aws_account_id: awsAccountId,
    }, {
      withCredentials: true,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error('Error checking AWS access:', error);
    return { success: false, error: error.message };
  }
};

export const importPermissionSchema = async () => {
  try {
    const response = await axios.post(`${config.apiUrl}/import-schema/import`, {}, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error importing permission schema:', error);
    return { error: error.message };
  }
};

export const initializeGithubRepo = async () => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/initialize-github`, {}, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error initializing GitHub repository:', error);
    return { error: error.message };
  }
};

export const configureGitHubRepo = async (owner, repo, roleArn, awsRegion) => {
  try {
    const response = await axios.post(`${config.apiUrl}/github-actions/configure-github-repo`, {
      owner,
      repo,
      roleArn,
      awsRegion,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error configuring GitHub repository:', error);
    return { error: error.message };
  }
};

// Slack settings related functions
export const fetchSlackSettings = async () => {
  try {
    const response = await axios.get(`${config.apiUrl}/settings/slack`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching Slack settings:', error);
    return { error: error.message };
  }
};

export const updateSlackSettings = async (slackId, slackChannel) => {
  try {
    const response = await axios.post(`${config.apiUrl}/settings/slack`, {
      slack_id: slackId,
      slack_channel: slackChannel,
    }, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating Slack settings:', error);
    return { error: error.message };
  }
};
