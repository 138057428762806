import React, { useState, useEffect, useRef } from "react";
import { Box, Button, TextField, Typography, Grid, Link, LinearProgress, Paper } from "@mui/material";
import axios from 'axios';
import config from "./config";
import PageLayout from "./components/PageLayout";
import { usePageVisibility } from './hooks/usePageVisibility'; // Adjust the path based on your file structure

const CreatePermissionChange = () => {
  const [requestedChange, setRequestedChange] = useState("");
  const [duration, setDuration] = useState("");
  const [requester, setRequester] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [pullRequestUrls, setPullRequestUrls] = useState([]);
  const [progress, setProgress] = useState({ step: "", percentage: 0 });
  const [requestId, setRequestId] = useState(null);
  const [hasStarted, setHasStarted] = useState(false);

  const githubFilePath = "/terraform/generated/aws/iam/";
  const isPageVisible = usePageVisibility();
  const timerIdRef = useRef(null);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const res = await axios.get(`${config.apiUrl}/profile`, { withCredentials: true });
        setRequester(res.data.name || "");
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };
    fetchUserInfo();
  }, []);

  useEffect(() => {
    const pollingCallback = async () => {
      if (requestId) {
        console.log(`Polling status for requestId: ${requestId}`);
        try {
          const res = await axios.get(`${config.apiUrl}/policyChangeRequests/status/${requestId}`, { withCredentials: true });
          const data = res.data;
          console.log("Polling status response:", data);
          if (data.status) {
            const steps = [
              "initiated",
              "selecting relevant files",
              "analyzing policy change request",
              "fetching file content",
              "analyzing file content",
              "creating pull request",
              "finished",
            ];
            const percentage = ((steps.indexOf(data.status) + 1) / steps.length) * 100;
            setProgress({ step: data.status, percentage });
            console.log("Updated progress:", { step: data.status, percentage });
            if (data.status === "finished" || data.status === "error creating pull request") {
              setPullRequestUrls(data.pull_request_url ? [data.pull_request_url] : []);
              clearInterval(timerIdRef.current);
            }
          }
        } catch (error) {
          console.error("Error fetching status:", error);
        }
      }
    };

    const startPolling = () => {
      pollingCallback(); // To immediately start fetching data
      timerIdRef.current = setInterval(pollingCallback, 5000); // Polling every 5 seconds
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPageVisible && requestId) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isPageVisible, requestId]);

  const handleRequest = async (e) => {
    e.preventDefault();
    setLoading(true);
    setProgress({ step: "Starting", percentage: 0 });
    setPullRequestUrls([]);
    setHasStarted(true);
    console.log("Sending policy change request:", { requestedChange, duration, githubFilePath, requester });
    try {
      const res = await axios.post(
        `${config.apiUrl}/policyChangeRequests/policy-change-request`,
        { requestedChange, duration, githubFilePath, requester },
        { withCredentials: true }
      );
      const data = res.data;
      console.log("Policy change request response:", data);
      if (data.error) {
        throw new Error(data.error);
      }
      setRequestId(data.requestId); // Save the requestId to start polling
      setResponse(data.analysis);
      setPullRequestUrls(data.pullRequestUrls || [data.pullRequestUrl]);
      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  return (
    <PageLayout title="Policy Change Request">
      <form onSubmit={handleRequest}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="requestedChange"
              label="Requested Change"
              name="requestedChange"
              autoComplete="requestedChange"
              value={requestedChange}
              onChange={(e) => setRequestedChange(e.target.value)}
              sx={{ backgroundColor: "#f1f3f5", borderRadius: "8px", fontFamily: "Inter, Arial, sans-serif" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="duration"
              label="Duration"
              name="duration"
              autoComplete="duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              sx={{ backgroundColor: "#f1f3f5", borderRadius: "8px", fontFamily: "Inter, Arial, sans-serif" }}
            />
          </Grid>
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 3,
            mb: 2,
            textTransform: "none",
            borderRadius: "50px",
            fontFamily: "Inter, Arial, sans-serif",
          }}
          disabled={loading}
        >
          {loading ? "Processing..." : "Submit"}
        </Button>
      </form>
      {hasStarted && progress.step && (
        <Box sx={{ mt: 3 }}>
          <Paper elevation={3} sx={{ p: 2, borderRadius: "12px" }}>
            <Typography variant="h6" align="center">
              {progress.step}
            </Typography>
            <LinearProgress
              variant="determinate"
              value={progress.percentage}
              sx={{ height: 10, borderRadius: 5, mt: 2 }}
            />
          </Paper>
        </Box>
      )}
      {response && (
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "#333",
              mt: 2,
              fontFamily: "Inter, Arial, sans-serif",
            }}
          >
            Analysis Response
          </Typography>
          <pre>{JSON.stringify(response, null, 2)}</pre>
        </Box>
      )}
      {progress.step === "finished" && pullRequestUrls.length > 0 && (
        <Box mt={2}>
          <Typography
            variant="body1"
            sx={{
              fontWeight: "bold",
              color: "#333",
              fontFamily: "Inter, Arial, sans-serif",
            }}
          >
            Pull Request(s) Created:
          </Typography>
          <ul>
            {pullRequestUrls.map((url, index) => (
              <li key={index}>
                <Link
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    color: "#0070f3",
                    fontFamily: "Inter, Arial, sans-serif",
                  }}
                >
                  {url}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </PageLayout>
  );
};

export default CreatePermissionChange;
