import React, { useState, useEffect } from 'react';
import { Typography, Box } from '@mui/material';
import PageLayout from './components/PageLayout';
import config from './config';
import ReactFlow, { ReactFlowProvider, Background, Controls } from 'reactflow';
import 'reactflow/dist/style.css';

const Dashboard = () => {
  const [iamData, setIamData] = useState(null);

  useEffect(() => {
    fetch(`${config.apiUrl}/dashboard/iam-data`, {
      credentials: 'include',
    })
      .then(response => response.json())
      .then(data => {
        console.log('IAM Data:', data); // Log the data
        setIamData(data);
      })
      .catch(error => console.error('Error fetching IAM data:', error));
  }, []);

  const formatDataForReactFlow = (iamData) => {
    if (!iamData || !iamData.users) return { nodes: [], edges: [] };

    const nodes = [];
    const edges = [];

    iamData.users.forEach((user, userIndex) => {
      const userNodeId = `user-${userIndex}`;
      nodes.push({
        id: userNodeId,
        type: 'input',
        data: { label: user.name },
        position: { x: 0, y: userIndex * 150 },
      });

      if (user.policies) {
        user.policies.forEach((policy, policyIndex) => {
          const policyNodeId = `policy-${userIndex}-${policyIndex}`;
          nodes.push({
            id: policyNodeId,
            data: { label: policy },
            position: { x: 200, y: userIndex * 150 + policyIndex * 50 },
          });
          edges.push({ id: `edge-${userNodeId}-${policyNodeId}`, source: userNodeId, target: policyNodeId });
        });
      }

      if (user.roles) {
        user.roles.forEach((role, roleIndex) => {
          const roleNodeId = `role-${userIndex}-${roleIndex}`;
          nodes.push({
            id: roleNodeId,
            data: { label: role },
            position: { x: 400, y: userIndex * 150 + roleIndex * 50 },
          });
          edges.push({ id: `edge-${userNodeId}-${roleNodeId}`, source: userNodeId, target: roleNodeId });
        });
      }
    });

    return { nodes, edges };
  };

  return (
    <PageLayout title="Dashboard">
      <Typography variant="h4" sx={{ mb: 2, fontFamily: 'Inter, Arial, sans-serif' }}>
        IAM Visualization
      </Typography>
      {iamData ? (
        <ReactFlowProvider>
          <Box sx={{ height: '600px', width: '100%', border: '1px solid #ccc' }}>
            <ReactFlow
              nodes={formatDataForReactFlow(iamData).nodes}
              edges={formatDataForReactFlow(iamData).edges}
              fitView
            >
              <Background />
              <Controls />
            </ReactFlow>
          </Box>
        </ReactFlowProvider>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </PageLayout>
  );
};

export default Dashboard;
