import React, { createContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import config from '../config';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/profile`, {
          credentials: 'include'
        });
        if (response.ok) {
          const data = await response.json();
          setUser(data);
        } else {
          setUser(null);
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    // Fetch user profile only if not on login or signup page
    if (!['/login', '/signup'].includes(location.pathname)) {
      fetchUser();
    } else {
      setLoading(false);
    }
  }, [location.pathname]);

  const login = async (username, password) => {
    try {
      const response = await fetch(`${config.apiUrl}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
        credentials: 'include'
      });

      if (response.ok) {
        const data = await response.json();
        setUser(data);
        navigate('/dashboard'); // Redirect to dashboard after successful login
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error('Error during login:', error);
      setUser(null);
    }
  };

  const logout = async () => {
    try {
      await fetch(`${config.apiUrl}/auth/logout`, {
        method: 'GET',
        credentials: 'include'
      });
      setUser(null);
      navigate('/login'); // Redirect to login page after logout
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);
