import React, { useEffect, useState } from 'react';
import { Paper, Typography, Table, TableBody, TableCell, TableHead, TableRow, TextField, CircularProgress, Box, MenuItem, Select, FormControl, InputLabel, Button, Tabs, Tab } from '@mui/material';
import { useLocation } from 'react-router-dom';
import config from './config';
import PageLayout from './components/PageLayout';

const ShowPermissionChangeHistory = () => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('department');
  const [expandedRow, setExpandedRow] = useState(null);
  const [pullRequestDetails, setPullRequestDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        console.log('[INFO] Fetching history');
        const res = await fetch(`${config.apiUrl}/policyChangeRequests/history`, {
          method: 'GET',
          credentials: 'include',
        });
        if (!res.ok) {
          throw new Error('Failed to fetch history');
        }
        const data = await res.json();
        if (!Array.isArray(data)) {
          throw new Error('Invalid data format');
        }
        setHistory(data);
        setLoading(false);
        console.log('[INFO] Successfully fetched history', data);

        // Check if there is a requestId in the URL
        const params = new URLSearchParams(location.search);
        const requestId = params.get('requestId');
        if (requestId) {
          const matchedRequest = data.find(item => item.request_id === requestId);
          if (matchedRequest) {
            setExpandedRow(matchedRequest.request_id);
            fetchPullRequestDetails(matchedRequest.pull_request_url);
          }
        }
      } catch (error) {
        console.error('[ERROR] Error fetching history:', error);
        setLoading(false);
      }
    };
    fetchHistory();
  }, [location.search]);

  const fetchPullRequestDetails = async (url) => {
    try {
      console.log(`[INFO] Fetching pull request details for URL: ${url}`);
      const res = await fetch(`${config.apiUrl}/reviewPullRequest/github/pull-request?url=${encodeURIComponent(url)}`, {
        method: 'GET',
        credentials: 'include',
      });
      console.log('[INFO] Fetch request made:', res);
      if (!res.ok) {
        throw new Error('Failed to fetch pull request details');
      }
      const data = await res.json();
      setPullRequestDetails(data);
      console.log('[INFO] Successfully fetched pull request details', data);
    } catch (error) {
      console.error('[ERROR] Error fetching pull request details:', error);
    }
  };

  const handleReviewClick = (item) => {
    console.log('[INFO] Review button clicked for item', item);
    setExpandedRow(item.request_id);
    fetchPullRequestDetails(item.pull_request_url);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const filteredHistory = history.filter(item => {
    const fieldValue = item[searchField];
    return fieldValue && fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase());
  });

  return (
    <PageLayout title="Policy Change History" maxWidth="lg">
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Search Field</InputLabel>
          <Select
            value={searchField}
            onChange={e => setSearchField(e.target.value)}
            label="Search Field"
          >
            <MenuItem value="department">Group Name</MenuItem>
            <MenuItem value="requester">Requester</MenuItem>
            <MenuItem value="request_text">Requested Change</MenuItem>
            <MenuItem value="status">Status</MenuItem>
            <MenuItem value="pull_request_url">GitHub Link</MenuItem>
            <MenuItem value="request_id">Request ID</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          fullWidth
          label="Search"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Request ID</TableCell>
              <TableCell>Group Name</TableCell>
              <TableCell>Requester</TableCell>
              <TableCell>Requested Change</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>GitHub Link</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredHistory.map((item, index) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell>{item.request_id}</TableCell>
                  <TableCell>{item.department}</TableCell>
                  <TableCell>{item.requester}</TableCell>
                  <TableCell>{item.request_text}</TableCell>
                  <TableCell>{item.duration || 'N/A'}</TableCell>
                  <TableCell>{item.status}</TableCell>
                  <TableCell>{new Date(item.created_at).toLocaleString()}</TableCell>
                  <TableCell>
                    {item.pull_request_url ? (
                      <a href={item.pull_request_url} target="_blank" rel="noopener noreferrer">
                        Link
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </TableCell>
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleReviewClick(item)}>Review</Button>
                  </TableCell>
                </TableRow>
                {expandedRow === item.request_id && pullRequestDetails && (
                  <TableRow>
                    <TableCell colSpan={9}>
                      <Box>
                        <Tabs value={activeTab} onChange={handleTabChange}>
                          {pullRequestDetails.files.map((file, index) => (
                            <Tab key={index} label={file.filename} />
                          ))}
                        </Tabs>
                        {pullRequestDetails.files.map((file, index) => (
                          <Box
                            key={index}
                            role="tabpanel"
                            hidden={activeTab !== index}
                            id={`tabpanel-${index}`}
                            aria-labelledby={`tab-${index}`}
                          >
                            {activeTab === index && (
                              <Box p={3}>
                                <pre>{file.patch}</pre>
                              </Box>
                            )}
                          </Box>
                        ))}
                        <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                          <Button variant="contained" color="primary">Approve and Apply</Button>
                          <Button variant="contained" color="secondary">Reject</Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </PageLayout>
  );
};

export default ShowPermissionChangeHistory;
