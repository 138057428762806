import React, { useState, useEffect } from 'react';
import { Typography, Tabs, Tab, Box } from '@mui/material';
import GitHubSettings from './GitHubSettings';
import AWSSettings from './AWSSettings';
import SlackSettings from './SlackSettings';
import { fetchAwsSettings } from './api';
import config from './config'; // Import the config object
import PageLayout from './components/PageLayout';
import { styled } from '@mui/material/styles';

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none', // Disable uppercase transformation
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.pxToRem(15),
  marginRight: theme.spacing(1),
  '&.Mui-selected': {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const Settings = () => {
  const [settings, setSettings] = useState({
    githubToken: '',
    githubRepo: '',
    githubOwner: '',
    githubBranch: '',
  });
  const [tokenLength, setTokenLength] = useState(0);
  const [loading, setLoading] = useState(true);
  const [awsSettings, setAwsSettings] = useState({}); // Add state for AWS settings
  const [tabIndex, setTabIndex] = useState(0); // Add state for tab index

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/settings`, {
          credentials: 'include'
        });
        const data = await response.json();
        setSettings({
          githubToken: data.github_token,
          githubRepo: data.github_repo,
          githubOwner: data.github_owner,
          githubBranch: data.github_branch,
        });
        setTokenLength(data.github_token.length);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching settings:', error);
        setLoading(false);
      }
    };

    const fetchAwsSettingsData = async () => {
      try {
        console.log("Attempting to fetch AWS settings...");
        const awsData = await fetchAwsSettings();
        console.log("AWS Settings fetched:", awsData);
        setAwsSettings(awsData);
      } catch (error) {
        console.error('Error fetching AWS settings:', error);
      }
    };

    fetchSettings();
    fetchAwsSettingsData();
  }, []);

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <PageLayout title="Settings">
      <Tabs
        value={tabIndex}
        onChange={(e, newValue) => setTabIndex(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="settings tabs"
        sx={{ borderBottom: 1, borderColor: 'divider', alignSelf: 'flex-start' }}
      >
        <CustomTab label="GitHub Settings" />
        <CustomTab label="AWS Settings" />
        <CustomTab label="Slack Settings" />
      </Tabs>
      <Box sx={{ mt: 3 }}>
        {tabIndex === 0 && (
          <GitHubSettings
            settings={settings}
            setSettings={setSettings}
            tokenLength={tokenLength}
            setTokenLength={setTokenLength}
            awsSettings={awsSettings} // Pass AWS settings as a prop
          />
        )}
        {tabIndex === 1 && <AWSSettings />}
        {tabIndex === 2 && <SlackSettings />}
      </Box>
    </PageLayout>
  );
};

export default Settings;
