import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Typography, Box, Tooltip, IconButton, Paper, Collapse, Popper, Grow, ClickAwayListener, MenuList, MenuItem } from '@mui/material';
import { Dashboard, AccountCircle, Lock, Assessment, ExitToApp, Settings as SettingsIcon, Menu as MenuIcon, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import config from '../config';
import { useSidebar } from '../context/SidebarContext';
import logo from '../assets/logo.png'; // Import the logo

const drawerWidth = 240;

const Sidebar = () => {
  const [user, setUser] = useState({});
  const [error, setError] = useState(null);
  const { open, toggleSidebar } = useSidebar();
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${config.apiUrl}/profile`, {
          credentials: 'include'
        });
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(errorText);
        }
        const data = await response.json();
        setUser(data);
      } catch (error) {
        setError(error.message);
        console.error('Error fetching user profile:', error);
      }
    };
    fetchUser();
  }, []);

  const handlePermissionChangesClick = (event) => {
    setAnchorEl(event.currentTarget);
    setSubmenuVisible(!submenuVisible);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) {
      return;
    }
    setSubmenuVisible(false);
  };

  const handleSubmenuItemClick = (path) => {
    navigate(path);
    setSubmenuVisible(false);
  };

  const menuItems = [
    { text: 'Dashboard', icon: <Dashboard sx={{ fontSize: '20px' }} />, path: '/dashboard' },
    { text: 'Account', icon: <AccountCircle sx={{ fontSize: '20px' }} />, path: '/account' },
    { text: 'Policy Changes', icon: <Lock sx={{ fontSize: '20px' }} />, path: '', subItems: [
        { text: 'Create Policy Change Request', path: '/create-permission-change' },
        { text: 'Show Policy Change History', path: '/permission-change-history' }
      ]
    },
    { text: 'Reports and Audit', icon: <Assessment sx={{ fontSize: '20px' }} />, path: '/reports' },
    ...(user.role === 'admin' ? [{ text: 'Settings', icon: <SettingsIcon sx={{ fontSize: '20px' }} />, path: '/settings' }] : []),
  ];

  const handleLogout = async () => {
    await fetch(`${config.apiUrl}/auth/logout`, {
      method: 'GET',
      credentials: 'include'
    });
    navigate('/login');
  };

  if (error) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: 2 }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? drawerWidth : 64,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : 64,
          boxSizing: 'border-box',
          backgroundColor: '#ffffff',
          transition: 'width 0.3s',
          borderRight: '1px solid #e0e0e0',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        },
      }}
    >
      <Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', py: 2 }}>
          <img src={logo} alt="Logo" style={{ width: open ? '160px' : '32px', transition: 'width 0.3s' }} />
        </Box>
        <Divider />
        <Box sx={{ textAlign: 'center', p: 2 }}>
          <Avatar 
            src={user.avatar_url ? `${config.apiUrl}${user.avatar_url}` : ''} 
            alt={user.name} 
            sx={{ 
              width: open ? 30 : 24, 
              height: open ? 34 : 24, 
              margin: '0 auto' 
            }} 
          />
          {open && (
            <>
              <Typography variant="body2" sx={{ fontFamily: 'Inter, Arial, sans-serif', fontWeight: 'bold', fontSize: '13px' }}>{user.name}</Typography>
              <Typography variant="body2" color="textSecondary" sx={{ fontFamily: 'Inter, Arial, sans-serif', fontSize: '11px' }}>{user.email}</Typography>
            </>
          )}
        </Box>
        <Divider />
        <List>
          {menuItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.subItems ? (
                <>
                  <Tooltip title={`Navigate to ${item.text}`} placement="right">
                    <ListItem button onClick={handlePermissionChangesClick} sx={{ '&:hover': { backgroundColor: '#f1f3f5' } }}>
                      <ListItemIcon sx={{ color: 'black', minWidth: '30px', justifyContent: 'center', alignItems: 'center' }}>
                        {item.icon}
                      </ListItemIcon>
                      {open && (
                        <>
                          <ListItemText primary={item.text} style={{ color: 'black', fontFamily: 'Inter, Arial, sans-serif', fontSize: '10px', fontWeight: 'bold' }} />
                          {submenuVisible ? <ExpandLess /> : <ExpandMore />}
                        </>
                      )}
                    </ListItem>
                  </Tooltip>
                  {open ? (
                    <Collapse in={submenuVisible} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                        {item.subItems.map((subItem, subIndex) => (
                          <ListItem button key={subIndex} onClick={() => handleSubmenuItemClick(subItem.path)} sx={{ pl: 4 }}>
                            <ListItemText primary={subItem.text} style={{ color: 'black', fontFamily: 'Inter, Arial, sans-serif', fontSize: '10px', fontWeight: 'bold' }} />
                          </ListItem>
                        ))}
                      </List>
                    </Collapse>
                  ) : (
                    <Popper open={submenuVisible} anchorEl={anchorEl} placement="right-start" transition>
                      {({ TransitionProps }) => (
                        <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList autoFocusItem={submenuVisible} id="menu-list-grow">
                                {item.subItems.map((subItem, subIndex) => (
                                  <MenuItem key={subIndex} onClick={() => handleSubmenuItemClick(subItem.path)}>
                                    {subItem.text}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  )}
                </>
              ) : (
                <Tooltip title={`Navigate to ${item.text}`} placement="right">
                  <ListItem button onClick={() => navigate(item.path)} sx={{ '&:hover': { backgroundColor: '#f1f3f5' } }}>
                    <ListItemIcon sx={{ color: 'black', minWidth: '30px', justifyContent: 'center', alignItems: 'center' }}>
                      {item.icon}
                    </ListItemIcon>
                    {open && <ListItemText primary={item.text} style={{ color: 'black', fontFamily: 'Inter, Arial, sans-serif', fontSize: '10px', fontWeight: 'bold' }} />}
                  </ListItem>
                </Tooltip>
              )}
            </React.Fragment>
          ))}
          <Divider />
          <Tooltip title="Log out of your account" placement="right">
            <ListItem button onClick={handleLogout} sx={{ '&:hover': { backgroundColor: '#f1f3f5' } }}>
              {/* Logout icon */}
              <ListItemIcon sx={{ color: 'black', minWidth: '30px', justifyContent: 'center', alignItems: 'center' }}><ExitToApp sx={{ fontSize: '20px' }} /></ListItemIcon>
              {open && <ListItemText primary="Logout" style={{ color: 'black', fontFamily: 'Inter, Arial, sans-serif', fontSize: '10px', fontWeight: 'bold' }} />}
            </ListItem>
          </Tooltip>
        </List>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: open ? 'flex-start' : 'center', py: 2 }}>
        <IconButton onClick={toggleSidebar} style={{ fontSize: '18px', padding: '20px' }}>
          <MenuIcon style={{ fontSize: '18px' }} />
        </IconButton>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
