import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Grid, Alert } from '@mui/material';
import { saveAwsAccount, downloadCloudFormationScript, saveAwsRoleArn, checkAwsAccess, fetchAwsSettings } from './api';

const AWSSettings = () => {
  const [awsAccountId, setAwsAccountId] = useState('');
  const [region, setRegion] = useState('');
  const [roleArn, setRoleArn] = useState('');
  const [githubOwner, setGithubOwner] = useState('');
  const [githubRepo, setGithubRepo] = useState('');
  const [isDownloading, setIsDownloading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetchAwsSettings();
        if (response) {
          setAwsAccountId(response.aws_account_id || '');
          setRegion(response.region || '');
          setRoleArn(response.role_arn || '');
          setGithubOwner(response.github_owner || '');
          setGithubRepo(response.github_repo || '');
        } else {
          console.error('Error fetching AWS settings');
        }
      } catch (error) {
        console.error('Error fetching AWS settings:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchSettings();
  }, []);

  const handleDownloadTemplate = async () => {
    setIsDownloading(true);
    const result = await downloadCloudFormationScript(awsAccountId, region, githubOwner, githubRepo);
    setIsDownloading(false);
    if (result.success) {
      const blob = new Blob([result.template], { type: 'text/yaml' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'cloudformation-template.yaml');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      // Save AWS account automatically after downloading the template
      const saveResult = await saveAwsAccount(awsAccountId, region);
      if (!saveResult.success) {
        setErrorMessage(`Error saving AWS account: ${saveResult.error}`);
      } else {
        setSuccessMessage('AWS account saved successfully.');
      }
    } else {
      setErrorMessage(`Error generating CloudFormation template: ${result.error}`);
    }
  };

  const handleSaveRoleArn = async () => {
    const result = await saveAwsRoleArn(awsAccountId, roleArn);
    if (!result.success) {
      setErrorMessage(`Error saving AWS role ARN: ${result.error}`);
    } else {
      setSuccessMessage('AWS role ARN saved successfully.');
    }
  };

  const handleCheckAwsAccess = async () => {
    const result = await checkAwsAccess(awsAccountId);
    if (result.success) {
      setSuccessMessage('AWS access check successful.');
    } else {
      setErrorMessage(`Error checking AWS access: ${result.error}`);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>; // Show a loading state
  }

  return (
    <Grid container spacing={2}>
      {successMessage && (
        <Grid item xs={12}>
          <Alert severity="success" onClose={() => setSuccessMessage('')}>
            {successMessage}
          </Alert>
        </Grid>
      )}
      {errorMessage && (
        <Grid item xs={12}>
          <Alert severity="error" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="AWS Account ID"
          name="awsAccountId"
          value={awsAccountId}
          onChange={(e) => setAwsAccountId(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          fullWidth
          label="Region"
          name="region"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          required
        />
      </Grid>
      <Grid item xs={12} sx={{ mt: 2 }}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleDownloadTemplate}
          disabled={isDownloading || !awsAccountId || !region}
          sx={{
            mt: 2,
            textTransform: 'none',
            borderRadius: '24px',
            backgroundColor: '#ffffff',
            borderColor: '#e0e0e0',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: '#f1f3f5',
              borderColor: '#c0c0c0',
            },
          }}
        >
          {isDownloading ? 'Downloading...' : 'Download CloudFormation Template'}
        </Button>
      </Grid>
      <Grid item xs={12} sx={{ mt: 4 }}>
        <TextField
          variant="outlined"
          fullWidth
          label="Role ARN"
          name="roleArn"
          value={roleArn}
          onChange={(e) => setRoleArn(e.target.value)}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSaveRoleArn}
          disabled={!roleArn}
          sx={{
            mt: 2,
            textTransform: 'none',
            borderRadius: '24px',
            backgroundColor: '#ffffff',
            borderColor: '#e0e0e0',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: '#f1f3f5',
              borderColor: '#c0c0c0',
            },
          }}
        >
          Save Role ARN
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          onClick={handleCheckAwsAccess}
          disabled={!awsAccountId || !roleArn}
          sx={{
            mt: 2,
            textTransform: 'none',
            borderRadius: '24px',
            backgroundColor: '#ffffff',
            borderColor: '#e0e0e0',
            color: '#1976d2',
            '&:hover': {
              backgroundColor: '#f1f3f5',
              borderColor: '#c0c0c0',
            },
          }}
        >
          Check AWS Access
        </Button>
      </Grid>
    </Grid>
  );
};

export default AWSSettings;
